import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { DocGeneration } from 'modules/api'
import { PDFThumbnail } from 'modules/import/types'
import { RootState } from 'modules/redux'
import { selectAllThemes } from 'modules/theming/themePicker/reducer'
import type { Theme } from 'modules/theming/types'

import { PptImportDocGenerateSettings } from './types'
import { originalThumbnailCard } from './utils'

type ImportPptStatus = 'importing' | 'done' | 'error'

export type ImportPPTState = {
  interactionId: string

  docGeneration: DocGeneration | null

  file: {
    filename: string
    htmlUrl: string
    fileUrl?: string
    thumbnails: PDFThumbnail[]
  } | null
  slides: ImportSlideState[]

  status: ImportPptStatus

  error: string | null

  themeId: string | null
}

export type ImportSlideState = {
  status: 'importing' | 'generating' | 'done' | 'error'

  error: string | null

  index: number
  thumbnail: PDFThumbnail

  startTime: number

  rating: 'poor' | 'good' | undefined
  deleted: boolean
  useOriginalImage: boolean

  // step 1
  parsedHtml?: string
  parsedHtmlTime?: number
  // step 3
  aiHtml?: string
  aiHtmlTime?: number
  // step 3
  cardContent?: JSONContent
  cardContentTime?: number
}

const initialState: ImportPPTState = {
  status: 'importing',
  // make this always a string since the initial `loadDocGeneration` should always
  // populate the interactionId, even if it's not present on the docGeneration.draftInput
  interactionId: '',
  error: null,
  docGeneration: null,
  file: null,
  slides: [],
  themeId: null,
}

const ImportPPTSlice = createSlice({
  name: 'ImportPPT',
  initialState,
  reducers: {
    resetImportState: () => initialState,
    loadDocGeneration(
      state,
      action: {
        payload: {
          docGeneration: DocGeneration
        }
      }
    ) {
      const { docGeneration } = action.payload
      if (!docGeneration.draftInput) {
        throw new Error(
          'Cannot load DocGeneration for ImportPPT with no draftInput'
        )
      }

      const { filename, htmlUrl, thumbnails, fileUrl } = docGeneration
        .draftInput.settings as PptImportDocGenerateSettings

      // right now we just generate a new interactionId when the doc generation is loaded
      // since each load of a doc generation kicks off a new AI import
      state.docGeneration = docGeneration
      state.file = { filename, htmlUrl, thumbnails, fileUrl }
      state.slides = thumbnails.map((thumbnail, index) => ({
        error: null,
        status: 'importing',
        useOriginalImage: false,
        rating: undefined,
        deleted: false,
        index,
        thumbnail,
        startTime: performance.now(),
      }))
    },

    importSlideError(
      state,
      action: {
        payload: { index: number; error: string }
      }
    ) {
      const { index, error } = action.payload
      if (!state.slides[index]) {
        console.error(
          `[ImportPptSlice] updateSlideState: slide ${index} does not exist`
        )
        return
      }
      Object.assign(state.slides[index], {
        status: 'error',
        // if we error out, we'll use the original image
        useOriginalImage: true,
        error,
      })
    },

    updateSlideState(
      state,
      action: {
        payload: Partial<ImportSlideState> & { index: number }
      }
    ) {
      const { index, ...rest } = action.payload
      if (!state.slides[index]) {
        console.error(
          `[ImportPptSlice] updateSlideState: slide ${index} does not exist`
        )
        return
      }
      Object.assign(state.slides[index], rest)
    },

    /**
     * Called when the stream errors at the top level
     */
    importPptStreamError(state, action: PayloadAction<{ error: string }>) {
      // go through all of the slides and mark them as done
      state.slides.forEach((slide) => {
        if (slide.status !== 'done') {
          slide.status = 'error'
          slide.useOriginalImage = true
        }
      })

      state.status = 'error'
      state.error = action.payload.error
    },

    /**
     * Called when the stream is finished, it may not have completed, but no more data is coming
     */
    importPptStreamDone(state) {
      // go through all of the slides and mark them as done
      state.slides.forEach((slide) => {
        // mark them as error because the stream is done and the data will
        // never arriev
        if (slide.status !== 'done') {
          slide.status = 'error'
          slide.useOriginalImage = true
        }
      })
      state.status = 'done'
      state.error = null
    },

    setPptPreviewThemeId(
      state,
      action: {
        payload: { themeId: string | null }
      }
    ) {
      state.themeId = action.payload.themeId
    },

    updateImportPptInteractionId(
      state,
      action: {
        payload: {
          interactionId: string
        }
      }
    ) {
      state.interactionId = action.payload.interactionId
    },
  },
})

export const {
  resetImportState,
  updateImportPptInteractionId,
  updateSlideState,
  loadDocGeneration,
  setPptPreviewThemeId,
  importPptStreamDone,
  importPptStreamError,
  importSlideError,
} = ImportPPTSlice.actions

export const ImportPPTReducer = ImportPPTSlice.reducer

export const selectImportPptSlides = (state: RootState) =>
  state.ImportPPT.slides

export const selectImportSlideState = (num: number) => (state: RootState) =>
  state.ImportPPT.slides[num - 1]

const selectTotalSlides = (state: RootState) =>
  state.ImportPPT.file?.thumbnails.length ?? 0

const selectNumImportedSlides = (state: RootState) =>
  state.ImportPPT.slides.filter((slide) => slide.status === 'done').length

export const selectPptPreviewThemeId = (state: RootState) =>
  state.ImportPPT.themeId

export const selectPptPreviewTheme = (state: RootState): Theme | undefined => {
  if (!state.ImportPPT.themeId) {
    return
  }

  return selectAllThemes(state).find(
    (theme) => theme.id === state.ImportPPT.themeId
  )
}

export const selectImportStatus = (state: RootState) => {
  const allSlidesDone = state.ImportPPT.slides.every(
    (slide) => slide.status === 'done'
  )

  const hadSlideError = state.ImportPPT.slides.some(
    (slide) => slide.status === 'error'
  )

  const importStatus = state.ImportPPT.status
  const totalSlides = selectTotalSlides(state)
  const numImportedSlides = selectNumImportedSlides(state)
  const hasFile = state.ImportPPT.file !== null

  const status: ImportPptStatus =
    importStatus === 'error' || (importStatus === 'done' && hadSlideError)
      ? 'error'
      : allSlidesDone && hasFile
      ? 'done'
      : 'importing'

  return {
    status,
    totalSlides,
    numImportedSlides,
  }
}

export const selectDocContentJSON = (state: RootState) => {
  return state.ImportPPT.slides
    .filter((a) => !a.deleted)
    .map((slide) => {
      if (slide.useOriginalImage) {
        return originalThumbnailCard(slide.thumbnail)
      }

      return slide.cardContent!
    })
}

export const selectImportPptHtmlUrl = (state: RootState): string | undefined =>
  state.ImportPPT.file?.htmlUrl

export const selectImportPPTState = (state: RootState) => state.ImportPPT

export const selectImportPptInteractionId = (state: RootState): string =>
  state.ImportPPT.interactionId

export const selectImportPptDraftInputId = (
  state: RootState
): string | undefined => state.ImportPPT.docGeneration?.draftInput?.id

export const selectImportPptDocGenerationId = (
  state: RootState
): string | undefined => state.ImportPPT.docGeneration?.id

import { Editor } from '@tiptap/core'

import { DocGenerateInfo, DocumentAttributes } from './attributes'
import { DOC_FORMAT_CATEGORIES, DocFormatKey } from './DocFormats'

export const DEFAULT_DOC_TYPE = 'deck'

export const getDocAttrs = (editor: Editor): DocumentAttributes => {
  return editor.state.doc.firstChild!.attrs as DocumentAttributes
}

export const getDocFormatFromAttrs = (attrs: DocumentAttributes) => {
  const docType = attrs.format || DEFAULT_DOC_TYPE
  return findDocFormat(docType)
}

export const findDocFormat = (key: DocFormatKey) => {
  return DOC_FORMAT_CATEGORIES[key] || DOC_FORMAT_CATEGORIES.deck
}

export const getDocGenerateInfo = (editor: Editor): DocGenerateInfo => {
  return getDocAttrs(editor).generateInfo
}

import {
  faAlignLeft,
  faBrowser,
  faExpandWide,
  faFileLines,
  faMobile,
  faRectangle,
  faRectangleHistory,
  faRectangleVertical,
  faRectangleWide,
  faSquare,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'

import { DocSettings } from './attributes'

export type DocFormatKey = 'deck' | 'webpage' | 'document' | 'social'

export type StyleTemplateKey =
  | 'deck_default'
  | 'deck_traditional'
  | 'deck_tall'
  | 'document_default'
  | 'document_pageless'
  | 'document_letter'
  | 'document_a4'
  | 'social_square'
  | 'social_portrait'
  | 'social_stories'
  | 'webpage_fluid'
  | 'custom'

export type StyleData = {
  label: () => string
  formatType: DocFormatKey
  cardDimensionsDesc: () => string
  recommendedFor: () => string
  icon: FontAwesomeIconProps['icon']
  docSettings: Pick<
    DocSettings,
    | 'stylesDerivedFrom'
    | 'cardDimensions'
    | 'verticalAlign'
    | 'defaultFullBleed'
    | 'defaultContentWidth'
    | 'fontSize'
  >
}

type PageStyleMap = {
  [key in Exclude<StyleTemplateKey, 'custom'>]: StyleData
}

export const STYLE_TEMPLATES: PageStyleMap = {
  deck_default: {
    label: () => t`Default`,
    formatType: 'deck',
    cardDimensionsDesc: () => t`Fluid`,
    recommendedFor: () =>
      t`Flexible cards that expand with your content. Great for informal presentations.`,
    icon: faExpandWide,
    docSettings: {
      stylesDerivedFrom: 'deck_default',
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
      fontSize: 'md',
    },
  },
  deck_traditional: {
    label: () => t`Traditional`,
    formatType: 'deck',
    cardDimensionsDesc: () => '16:9',
    recommendedFor: () =>
      t`Standard size for exporting to PowerPoint, Google Slides, Keynote`,
    icon: faRectangleWide,
    docSettings: {
      stylesDerivedFrom: 'deck_traditional',
      cardDimensions: '16x9',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
      fontSize: 'md',
    },
  },
  deck_tall: {
    label: () => t`Tall`,
    formatType: 'deck',
    cardDimensionsDesc: () => '4:3',
    recommendedFor: () => t`Good for text-heavy presentations or handouts`,
    icon: faRectangle,
    docSettings: {
      stylesDerivedFrom: 'deck_tall',
      cardDimensions: '4:3',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
      fontSize: 'md',
    },
  },

  document_default: {
    label: () => t`Default`,
    formatType: 'document',
    cardDimensionsDesc: () => t`Fluid`,
    recommendedFor: () => t`Good for online docs and content`,
    icon: faExpandWide,
    docSettings: {
      stylesDerivedFrom: 'document_default',
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'md',
      fontSize: 'md',
    },
  },
  document_pageless: {
    label: () => t`Pageless`,
    formatType: 'document',
    recommendedFor: () => t`Good for articles, notes, and internal docs`,
    cardDimensionsDesc: () => t`Fluid`,
    icon: faAlignLeft,
    docSettings: {
      stylesDerivedFrom: 'document_pageless',
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'full',
      defaultContentWidth: 'md',
      fontSize: 'md',
    },
  },
  document_letter: {
    label: () => t`Letter`,
    formatType: 'document',
    recommendedFor: () => t`Good for ebooks, printing, and exporting to PDF`,
    cardDimensionsDesc: () => t`US letter`,
    icon: faRectangleVertical,
    docSettings: {
      stylesDerivedFrom: 'document_letter',
      cardDimensions: 'letter_portrait',
      verticalAlign: 'start',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'md',
      fontSize: 'md',
    },
  },
  document_a4: {
    label: () => t`A4`,
    formatType: 'document',
    recommendedFor: () => t`Good for ebooks, printing, and exporting to PDF`,
    cardDimensionsDesc: () => t`A4`,
    icon: faRectangleVertical,
    docSettings: {
      stylesDerivedFrom: 'document_a4',
      cardDimensions: 'a4_portrait',
      defaultFullBleed: 'contained',
      verticalAlign: 'start',
      defaultContentWidth: 'md',
      fontSize: 'md',
    },
  },
  social_square: {
    label: () => t`Square`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram and social posts`,
    icon: faSquare,
    cardDimensionsDesc: () => '1:1',
    docSettings: {
      stylesDerivedFrom: 'social_square',
      cardDimensions: 'square',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  social_portrait: {
    label: () => t`Portrait`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram posts and Linkedin carousels`,
    icon: faRectangleVertical,
    cardDimensionsDesc: () => '4:5',
    docSettings: {
      stylesDerivedFrom: 'social_portrait',
      cardDimensions: '4:5',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  social_stories: {
    label: () => t`Stories`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram stories and TikTok reels`,
    icon: faMobile,
    cardDimensionsDesc: () => '9:16',
    docSettings: {
      stylesDerivedFrom: 'social_stories',
      cardDimensions: '16x9_portrait',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  webpage_fluid: {
    label: () => t`Webpage`,
    formatType: 'webpage',
    recommendedFor: () => t`Good for websites and landing pages`,
    cardDimensionsDesc: () => t`Fluid`,
    icon: faBrowser,
    docSettings: {
      stylesDerivedFrom: 'webpage_fluid',
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultContentWidth: 'lg',
      defaultFullBleed: 'full',
      fontSize: 'md',
    },
  },
} as const

function findMatchingPageStyleKey(format: DocFormatKey): StyleTemplateKey[] {
  return (Object.keys(STYLE_TEMPLATES) as StyleTemplateKey[]).filter(
    (styleKey: StyleTemplateKey) => {
      const style = STYLE_TEMPLATES[styleKey]
      return style.formatType === format
    }
  )
}

type DocFormatCategories = {
  [formatType in DocFormatKey]: {
    name: () => string
    formatType: DocFormatKey
    description: () => string
    icon: FontAwesomeIconProps['icon']
    styleTemplates: StyleTemplateKey[]
  }
}

export const DOC_FORMAT_CATEGORIES: DocFormatCategories = {
  deck: {
    name: () => t`Presentation`,
    formatType: 'deck',
    description: () =>
      t`A slide deck. Good for live presentations or shareable decks.`,
    icon: faRectangleHistory,
    styleTemplates: findMatchingPageStyleKey('deck'),
  },
  document: {
    name: () => t`Document`,
    formatType: 'document',
    description: () =>
      t`Pageless or printable pages. Good for ebooks, documents and PDFs.`,
    icon: faFileLines,
    styleTemplates: findMatchingPageStyleKey('document'),
  },
  social: {
    name: () =>
      t({
        message: `Social`,
        comment: `Short descriptor for "social media carousel" or "graphic posted on social media"`,
      }),
    formatType: 'social',
    description: () => t`Graphics or carousels for social media.`,
    icon: faMobile,
    styleTemplates: findMatchingPageStyleKey('social'),
  },
  webpage: {
    name: () => t`Webpage`,
    formatType: 'webpage',
    description: () =>
      t`Interactive page with distinct sections. Good for landing pages and websites.`,
    icon: faBrowser,
    styleTemplates: findMatchingPageStyleKey('webpage'),
  },
}

export type FontSize = 'sm' | 'md' | 'lg'

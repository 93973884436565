import { LDMultiKindContext } from 'launchdarkly-js-client-sdk'
import { useEffect, useState } from 'react'

import { config } from 'config'
import { useHealthCheck } from 'modules/api/healthCheck'
import { getDeploymentHash } from 'modules/app/utils/getDeploymentHash'
import { getProductForWorkspace } from 'modules/monetization/utils'
import { isMobileDevice, isRobot } from 'utils/deviceDetection'

import { getUserMetadataStore } from '../performance/UserMetadataStore'
import { useUserContext } from '../user'
import { featureFlags } from './FeatureFlagProvider'
import { initLDClient } from './utils'

export const LD_ANONYMOUS_USER_KEY = 'ld_anonymous_user'

// This is only a subset of the user metadata
// we get from the user metadata store via
// event-tracking/user-context
type UserMetadata = {
  browser?: {
    name?: string
    version?: string
  }
  device?: {
    model?: string
    type?: string
  }
  locale?: string
  location?: {
    city?: string
    country?: string
    latitude?: number
    longitude?: number
  }
}

const getCustomAttributes = ({
  email,
  workspaceId,
  userMetadata,
}: {
  email?: string
  workspaceId?: string
  userMetadata?: UserMetadata
}) => {
  const { browser, device, locale, location } = userMetadata || {}
  return {
    isGammaE2E: config.GAMMA_E2E_USER,
    isGammaUser: email ? email.endsWith('@gamma.app') : false,
    orgId: workspaceId || 'no_org_id',
    isMobileDevice: isMobileDevice(),
    deployTime: config.DEPLOYMENT_TIMESTAMP,
    deploymentHash: getDeploymentHash(config.NEXT_PUBLIC_VERCEL_URL || ''),
    browser,
    device,
    locale,
    location,
  }
}

export const FeatureFlagWrapper = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { user, anonymousUser, currentWorkspace, isUserLoading } =
    useUserContext()
  const { isConnected } = useHealthCheck()
  const [userMetadata, setUserMetadata] = useState<UserMetadata | null>(null)

  useEffect(() => {
    getUserMetadataStore()
      .fetch()
      .then((metadata) => {
        setUserMetadata(metadata)
      })
      .catch(() => {
        // noop
      })
  }, [])

  useEffect(() => {
    if (isUserLoading || typeof window === 'undefined' || !isConnected) {
      return
    }

    if (isRobot() && !config.GAMMA_E2E_USER) {
      return
    }

    let ldMultiContext: LDMultiKindContext
    // https://docs.launchdarkly.com/sdk/features/user-config
    if (user) {
      const {
        id,
        firstName,
        lastName,
        email,
        profileImageUrl,
        jobFunction,
        createdTime,
      } = user

      ldMultiContext = {
        kind: 'multi',
        user: {
          key: id,
          firstName,
          lastName,
          email,
          anonymous: false,
          avatar: profileImageUrl,
          jobFunction,
          userSettings: user.settings || {},
          createdTime,
          ...getCustomAttributes({
            email,
            workspaceId: currentWorkspace?.id,
            userMetadata: userMetadata || undefined,
          }),
        },
        // NB: If a user switches workspaces, we need to re-identify them with the current workspace
        ...(currentWorkspace?.id
          ? {
              workspace: {
                key: currentWorkspace?.id,
                name: currentWorkspace?.name,
                plan: getProductForWorkspace(currentWorkspace),
              },
            }
          : {}),
      }
    } else {
      /**
       * From https://docs.launchdarkly.com/sdk/features/user-config#designating-anonymous-users
       * "We recommend using the same user key for every initialization and
       * then replacing that with the actual user key when you know who the user is.
       * This way LaunchDarkly counts the initialization user key only once
       * against your MAU, instead of every time you initialize."
       */
      ldMultiContext = {
        kind: 'multi',
        user: {
          key: LD_ANONYMOUS_USER_KEY,
          anonymous: true,
          ...getCustomAttributes({
            userMetadata: userMetadata || undefined,
          }),
        },
      }
    }
    if (!featureFlags.hasInitialized) {
      // Now that we have our Gamma User, initialize the LD client
      initLDClient(config.LAUNCH_DARKLY_CLIENT_SIDE_ID, ldMultiContext)
        // Once the LDClient is initialize, proceed with our own provider
        .then(({ flags, ldClient }) => {
          featureFlags.initialize(ldClient, flags)
        })
    } else {
      // We've already initialized, so just re-identify the user
      featureFlags.initializePromise.then(() => {
        featureFlags.ldClient.identify(ldMultiContext)
      })
    }
  }, [
    isUserLoading,
    isConnected,
    user,
    anonymousUser,
    currentWorkspace,
    userMetadata,
  ])

  return <>{children}</>
}

import memoize from 'fast-memoize'

import type { Theme } from 'modules/theming/types'
import {
  ContainerWidth,
  VerticalAlignment,
} from 'modules/tiptap_editor/styles/types'
import { isImageExport, isPdfExport, isScreenshot } from 'utils/export'

import { FontSize } from '../Document/DocumentAttrs/DocFormats'
import { CardDimensionsOption } from './CardDimensions'
import { NESTED_CARD_OUTDENT } from './constants'
import { CardAttributes } from './types'

const CHAR_WIDTH = 0.5 // The average width of a character, relative to its height. Future: update based on font
const CARD_INNER_PADDING_X = 3.5 // In ems. Will scale with the font size
const CARD_MOBILE_INNER_PADDING_X = 1.5 // In ems. Will scale with the font size
const CARD_INNER_PADDING_Y = 2.75 // In ems. Will scale with the font size
const CARD_MOBILE_INNER_PADDING_Y = 1.5 // In ems. Will scale with the font size
const NESTED_CARD_MOBILE_INNER_PADDING_Y = 2.25 // allow for collapse and expand buttons to not cover content
const CARD_OUTER_PADDING_Y = 2
const CARD_OUTER_PADDING_Y_MOBILE = 1
const CARD_OUTER_PADDING_X = 2
const CARD_OUTER_PADDING_X_MOBILE = 0.5
const CARD_BACKGROUND_PADDING = 8
export const NARROW_CONTENT_WIDTH_CHARS = 70
export const NORMAL_CONTENT_WIDTH_CHARS = 95
export const WIDE_CONTENT_WIDTH_CHARS = 115
export const CONTENT_WIDTH_EM = NORMAL_CONTENT_WIDTH_CHARS * CHAR_WIDTH

export type CardSizeCSSVarsProps = {
  isPresentMode: boolean
  isNested: boolean
  nestedDepth: number
  isEditable: boolean
  isFullBleed: boolean
  displayFullBleed: boolean
  isThumbnail?: boolean
  theme: Theme
  attrs: CardAttributes
  cardDimensions: CardDimensionsOption
  defaultContentWidth?: ContainerWidth
  contentWidth?: ContainerWidth
  showPresentBackdrop: boolean
  userZoomLevel: number
  isMobileDevice: boolean
  verticalAlign: VerticalAlignment
  fontSize?: FontSize
  shouldUseNewPageStyles?: boolean
}

// Don't use this function directly - use the memoized getCardSizeCSSVars
const generateCardSizeCSSVars = ({
  isPresentMode,
  isNested,
  nestedDepth,
  isFullBleed,
  displayFullBleed,
  isThumbnail,
  theme,
  cardDimensions,
  defaultContentWidth,
  contentWidth,
  showPresentBackdrop,
  attrs,
  userZoomLevel,
  isMobileDevice,
  verticalAlign,
  fontSize,
  shouldUseNewPageStyles = false,
}: CardSizeCSSVarsProps) => {
  const hasCardBackground = attrs.background.type !== 'none' && !isNested
  // In present mode/export, we want consistent padding between slides
  // In doc mode, we want padding to scale with the font size (mobile vs filmstrip vs desktop)
  const paddingUnit = isThumbnail ? 'em' : isPresentMode ? 'rem' : 'em'

  // Outer padding = the padding between the card body and the wrapper, where you see the backdrop
  const outerPaddingX =
    displayFullBleed || isNested
      ? `0px`
      : isMobileDevice
      ? `${CARD_OUTER_PADDING_X_MOBILE}${paddingUnit}`
      : `${CARD_OUTER_PADDING_X}${paddingUnit}`
  const outerPaddingLeft = `calc(var(--card-outer-padding-x) + ${
    isFullBleed || isNested ? '0px' : 'var(--doc-padding-left, 0px)'
  } + ${isPresentMode ? 'var(--present-padding-left, 0px)' : '0px'})`
  const outerPaddingRight = `calc(var(--card-outer-padding-x) + ${
    isFullBleed || isNested ? '0px' : 'var(--doc-padding-right, 0px)'
  } + ${isPresentMode ? 'var(--present-padding-right, 0px)' : '0px'})`

  const outerPaddingY =
    displayFullBleed || isNested
      ? `0px`
      : isMobileDevice
      ? `${CARD_OUTER_PADDING_Y_MOBILE / 2}${paddingUnit}`
      : hasCardBackground && !isPresentMode && !isThumbnail
      ? `${CARD_BACKGROUND_PADDING}${paddingUnit}`
      : `${CARD_OUTER_PADDING_Y}${paddingUnit}`

  // Card sizing is designed to always show a max number of characters on a line, to ensure readability
  // which ultimately translates to contentWidthEms: the width of the content, relative to the font size

  const {
    baseFontSize,
    contentWidthEms,
    cardWidthEms,
    innerPaddingX,
    commentPadding,
  } = getCardWidthAndFontSize({
    theme,
    fontSize,
    isMobileDevice,
    contentWidth: contentWidth ?? defaultContentWidth,
    nestedDepth,
    shouldUseNewPageStyles,
  })

  // Remaining variables like font size, card width, etc. depend on the mode, doc settings, and device
  const modeSizeFn: ModeFn =
    isPresentMode || isThumbnail
      ? cardDimensions.aspectRatio && !isScreenshot // Avoid black bars in screenshots, which are fixed size
        ? getPresentFixedSizes
        : getPresentFluidSizes
      : isMobileDevice
      ? getMobileSizes
      : getDocModeSizes

  const modeSizeVars = modeSizeFn({
    baseFontSize,
    contentWidthEms,
    cardWidthEms,
    cardAspectRatio: cardDimensions.aspectRatio,
    showPresentBackdrop,
    isNested,
    isFullBleed,
    isThumbnail,
  })

  return {
    '--card-inner-padding-x': `${innerPaddingX}em`,
    '--card-inner-padding': `var(--card-inner-padding-y) var(--card-inner-padding-x)`,
    '--card-outer-padding-left': outerPaddingLeft,
    '--card-outer-padding-right': outerPaddingRight,
    '--card-outer-padding-x': outerPaddingX,
    '--card-outer-padding-y': outerPaddingY,
    '--comment-padding': `${commentPadding}em`,
    '--nested-card-margin': `calc(-1 * var(--comment-padding))`,
    '--card-vertical-align': verticalAlign,
    '--top-accent-height': '12.5em',
    '--behind-accent-height': '24em',
    // Used for zooming in present mode. When autozoom is enabled, this will be overridden by manipulating element style directly
    '--zoom-level': userZoomLevel,
    ...modeSizeVars,
  }
}

export const getCardWidthAndFontSize = ({
  theme,
  fontSize,
  isMobileDevice,
  contentWidth,
  nestedDepth = 0,
  shouldUseNewPageStyles = false,
}: {
  theme: Theme
  fontSize?: FontSize
  isMobileDevice?: boolean
  contentWidth?: ContainerWidth
  nestedDepth?: number
  shouldUseNewPageStyles?: boolean
}) => {
  const numericFontSize =
    fontSize === 'sm' ? 0.875 : fontSize === 'lg' ? 1.25 : 1
  const deviceScaleFactor = isMobileDevice ? 1 : 1.125

  const maxCharsPerLine =
    contentWidth === 'lg'
      ? WIDE_CONTENT_WIDTH_CHARS
      : contentWidth === 'sm'
      ? NARROW_CONTENT_WIDTH_CHARS
      : NORMAL_CONTENT_WIDTH_CHARS
  // Some fonts are bigger than others, even at a fixed size like 18px, so this is a fudge factor
  // that lets us scale some up. Future todo: calculate this automatically, or make it a font-level property
  const themeFontSize = theme.config.fontSize ?? 1
  let baseFontSize = themeFontSize * deviceScaleFactor
  const charWidth = CHAR_WIDTH / themeFontSize
  let contentWidthEms = maxCharsPerLine * charWidth
  if (shouldUseNewPageStyles) {
    baseFontSize = baseFontSize * numericFontSize
    contentWidthEms = contentWidthEms / baseFontSize
  }

  const nestedBulgeEms = nestedDepth * NESTED_CARD_OUTDENT
  const innerPaddingX = isMobileDevice
    ? CARD_MOBILE_INNER_PADDING_X
    : CARD_INNER_PADDING_X
  const cardWidthEms = contentWidthEms + innerPaddingX * 2 + nestedBulgeEms * 2
  const commentPadding = innerPaddingX + nestedBulgeEms

  return {
    baseFontSize,
    contentWidthEms,
    themeFontSize,
    innerPaddingX,
    cardWidthEms,
    commentPadding,
  }
}

export const getCardSizeCSSVars = memoize(generateCardSizeCSSVars)

type CardSizeHelperProps = {
  baseFontSize: number
  contentWidthEms: number
  cardWidthEms: number
  cardAspectRatio: number | null
  showPresentBackdrop: boolean
  isNested: boolean
  isFullBleed: boolean
  isThumbnail?: boolean
  numericFontSize?: number
}

type ModeFn = (args: CardSizeHelperProps) => { [cssProp: string]: string }

/* 
In doc mode, font size is in rem (= 16px * browser zoom)
The card width is scaled to fit a certain number of characters per line
Most units should be in ems, so they scale with the font size 
*/
const getDocModeSizes: ModeFn = ({
  contentWidthEms,
  cardWidthEms,
  cardAspectRatio,
  isFullBleed,
  isNested,
  baseFontSize,
}) => {
  const innerPaddingY =
    CARD_INNER_PADDING_Y + (isFullBleed ? CARD_OUTER_PADDING_Y : 0)
  // If we're using fixed sized cards, take on a min height
  const minCardHeight =
    cardAspectRatio && !isNested
      ? `calc(min(var(--card-width), var(--editor-width)) / ${cardAspectRatio})`
      : '0px' // This needs to be 0 not undefined because nested inherits from parent

  return {
    '--font-size': `calc(${baseFontSize} * var(--editor-font-size, 1rem))`,
    '--card-inner-padding-y': `${innerPaddingY}em`,
    '--card-width': `${cardWidthEms}em`, // Using ems makes this scale with the font size
    '--card-min-height': minCardHeight,
    '--max-content-width': `${contentWidthEms}em`,
    '--card-max-width': `var(--editor-width)`,
  }
}

// Mobile should follow the same rulesas doc mode, but with smaller padding
const getMobileSizes: ModeFn = ({
  cardWidthEms,
  baseFontSize,
  isNested,
  cardAspectRatio,
  isFullBleed,
}) => {
  const innerPaddingY = isNested
    ? NESTED_CARD_MOBILE_INNER_PADDING_Y
    : CARD_MOBILE_INNER_PADDING_Y +
      (isFullBleed ? CARD_OUTER_PADDING_Y_MOBILE : 0)
  // If we're using fixed sized cards, take on a min height
  const minCardHeight =
    cardAspectRatio && !isNested
      ? `calc(min(var(--card-width), var(--editor-width)) / ${cardAspectRatio})`
      : '0px' // This needs to be 0 not undefined because nested inherits from parent
  return {
    '--card-inner-padding-y': `${innerPaddingY}em`,
    '--font-size': `${baseFontSize}rem`,
    '--card-width': `${cardWidthEms}em`, // Using ems makes this scale with the font size
    '--card-min-height': minCardHeight,
    '--top-accent-height': '8em',
    '--behind-accent-height': '12em',
    '--card-max-width': `var(--editor-width)`,
  }
}

/*
In fluid present mode, the card body fills the viewport (100vw, 100vh)
The font size is a function of the card width, which is a function of the viewport width
On the low end, it's clamped to be a minimum of 1rem for readability
On the high end, it's clamped based on viewport height to reduce scrolling
*/
const getPresentFluidSizes: ModeFn = ({
  contentWidthEms,
  cardWidthEms,
  showPresentBackdrop,
  isFullBleed,
  baseFontSize,
  isNested,
  isThumbnail,
}) => {
  const innerPaddingY = CARD_INNER_PADDING_Y
  const cardMaxWidth = `calc(var(--editor-width) - 2 * var(--card-outer-padding-x))`
  const minFontSize = isThumbnail ? '0rem' : '1rem'
  const fontSize =
    // Image and PDF expand to fit the content, so we dont want to clamp size to the viewport
    isPdfExport || isImageExport
      ? `${baseFontSize}rem`
      : `calc(var(--zoom-level) * clamp(${minFontSize}, var(--card-max-width) / ${cardWidthEms}, 3vh))`

  // If you're on a wide screen, we stretch the card to fill the whole thing and rely on max-content-width to keep it readable
  // Unless you're showing the present backdrop, in which case we leave room for it on the sides
  const fillViewport = !showPresentBackdrop || isFullBleed

  return {
    '--card-inner-padding-y': `${innerPaddingY}em`,
    '--font-size': fontSize,
    '--card-width': fillViewport ? 'var(--editor-width)' : `${cardWidthEms}em`,
    '--card-max-width': cardMaxWidth,
    '--card-min-height': isThumbnail
      ? 'var(--thumbnail-min-height, 0px)'
      : fillViewport && !isNested
      ? '100vh'
      : '0vh',
    // In present mode, it's more important to use the full width and minimize scrolling,
    // so let content go a little wider
    '--max-content-width': `calc(${
      contentWidthEms * 1.15
    }em / var(--zoom-level))`,
  }
}

/*
In fixed size like 16x9, we start by calculating the max width/height that can fit the viewport
Then work backwards to calculate the font size as a function of the width

*/
const getPresentFixedSizes: ModeFn = ({
  cardAspectRatio: aspectRatio,
  cardWidthEms,
  isNested,
}) => {
  // The shape of the viewport we're trying to fill
  const cardMaxWidth = `calc(var(--editor-width) - 2 * var(--card-outer-padding-x))`
  // Image and PDF expand to fit the content, so we dont want to clamp size to the viewport
  const cardMaxHeight =
    isPdfExport || isImageExport
      ? `calc(var(--card-max-width) / ${aspectRatio})`
      : `calc(100vh - 2 * var(--card-outer-padding-y))`

  // The largest box with the specified aspect ratio that can fit in the viewport
  const cardWidth = `min(var(--card-max-width), calc(var(--card-max-height) * ${aspectRatio}))`

  // Cards with short content should be made taller to fit the aspect ratio
  const minCardHeight = isNested ? '0px' : `calc(${cardWidth} / ${aspectRatio})`

  const innerPaddingY = CARD_INNER_PADDING_Y
  const fontSize = `calc(var(--zoom-level) * ${cardWidth} / ${cardWidthEms})`

  return {
    '--card-inner-padding-y': `${innerPaddingY}em`,
    '--card-width': cardWidth,
    '--font-size': fontSize,
    '--card-max-width': cardMaxWidth,
    '--card-max-height': cardMaxHeight,
    '--card-min-height': minCardHeight,
  }
}
